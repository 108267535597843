import React, {useEffect, useState} from 'react';
import {
    GET_COLLECTION_DATA
} from '../../../api';
import moment from "moment";
import 'datatables.net';
import 'datatables.net-responsive';
import 'datatables.net-responsive-dt';
import 'datatables.net-editor';
import 'components-jqueryui';
import $ from "jquery";
import 'select2';
import {Link} from "react-router-dom";
import cogoToast from "cogo-toast";
import {capitalize} from "../../../utils/capitalize";
import {GoogleMap, Polygon, useLoadScript} from "@react-google-maps/api";
import {useSelector} from "react-redux";

let coordinate = null;

const GMap = () => {
    const { isLoaded } = useLoadScript({
        googleMapsApiKey: "AIzaSyBcwPZxow61r9xp4GpD5CJDsJzz1WEXW8Y",
        libraries: ["drawing"]
    });

    const onLoad = React.useCallback(function callback(map) {
        const zoomLevel = 10;
        let bounds = new window.google.maps.LatLngBounds();
        coordinate.forEach(coord => {
            bounds.extend(coord);
        });
        map.fitBounds(bounds);
        map.setZoom(zoomLevel);
    }, []);

    return (
        <>
            {!isLoaded ? (
                <h6 style={{marginTop: 40}}>Loading Map...</h6>
            ) : (
                <GoogleMap
                    mapContainerClassName="map-container"
                    onLoad={onLoad}
                    zoom={12}
                >
                    <Polygon
                        paths={coordinate}
                        options={{
                            fillOpacity: 0.35,
                            fillColor: '#FD2311',
                            strokeColor: '#FD2311',
                            strokeOpacity: 0.8,
                            strokeWeight: 2,
                        }}
                    />
                </GoogleMap>
            )}
        </>
    );
};

const Zone = () => {

    document.title = "Zones";

    const auth = useSelector((state) => state.auth.user);

    const token = localStorage.getItem('jwtToken');
    const [openMap, setOpenMap] = useState(false);

    useEffect(() => {

        let table = $('#zone_table').DataTable({
            responsive: true,
            language: {
                searchPlaceholder: 'Search name',
                sSearch: '',
                lengthMenu: '_MENU_ items/page',
                processing: '<div class="spinner-border"></div><p class="mg-t-10 tx-12">Collecting Zone Data</p>'
            },
            searching: true,
            lengthMenu: [[10], [10]],
            ordering: false,
            info: true,
            bFilter: false,
            processing: true,
            pageLength: 10,
            serverSide: true,
            ajax: function(data, callback) {
                $.ajax(GET_COLLECTION_DATA, {
                    type: 'POST',
                    headers: {
                        "Authorization": token
                    },
                    data: {
                        query: $('.dataTables_filter input').val(),
                        collection: "Zone",
                        fieldname: "name",
                        pageSize: data.start,
                        populate: [
                            {
                                path: 'products',
                                model: 'product',
                                populate: {
                                    path: 'property',
                                    model: 'property',
                                }
                            },
                            {
                                path: 'subdivisions.products',
                                model: 'product',
                                populate: {
                                    path: 'property',
                                    model: 'property',
                                }
                            }
                        ],
                        format: 'json',
                        pageIndex: (data.length + data.start) / data.length
                    },
                    success: function (res) {
                        let array = [];
                        res.data.map((data) => {
                            array.push({raw: data})
                        });

                        coordinate = array;

                        callback({
                            recordsTotal: res.total,
                            recordsFiltered: res.total,
                            data: array
                        });
                    },
                    error: function(err) {
                        cogoToast.error( err.responseJSON['error'].details !== undefined ? err.responseJSON['error'].details[0].message : err.responseJSON['error'], {position: "top-right", hideAfter: 5});
                    }
                });
            },
            "columns": [
                {
                    className: 'dt-control',
                    orderable: false,
                    data: null,
                    defaultContent: '',
                },
                {"data": "raw"},
                {"data": "raw"},
                {"data": "raw"},
                {"data": "raw"}
            ],
            "columnDefs": [
                {
                    "render": function () {
                        return null;
                    },
                    "targets": 0
                },
                {
                    "render": function (data) {
                        return data.name;
                    },
                    "targets": 1
                },
                {
                    "render": function (data) {
                        return {
                            "active" : `<span class="badge badge-success tx-white">${capitalize(data.status)}</span>`,
                            "inactive" : `<span class="badge badge-danger tx-white">${capitalize(data.status)}</span>`
                        }[data.status];
                    },
                    "targets": 2
                },
                {
                    "render": function (data) {
                        return moment(data.date.str).format("Do MMM, YYYY");
                    },
                    "targets": 3
                },
                {
                    "render": function (data) {
                        return `<nav class="nav nav-icon-only"><a href="#" class="nav-link view_zone"><i class="fa fa-eye"></i> View Map Zone</a></nav>`
                    },
                    "targets": 4
                }
            ]
        });

        table.on('click', '.view_zone', function(e) {
            e.preventDefault();

            let extract_td = table.row($(this).closest('tr')).data();
            coordinate = extract_td.raw.geometry.coordinates.flatMap(array => array.map(([lat, lng]) => ({ "lat": lat, "lng": lng })));

            $("#zone-name").text(extract_td.raw.name);
            $("#modalZone").modal({ backdrop: 'static', focus: false, show: true });
            setOpenMap(true);
        });

        table.on('click', 'td.dt-control', function () {
            let tr = $(this).closest('tr');
            let row = table.row(tr);

            if (row.child.isShown()) {
                // This row is already open - close it
                row.child.hide();
                tr.removeClass('shown');
            } else {
                // Open this row
                row.child(format(row.data())).show();
                tr.addClass('shown');
            }
        });

        let raw  = null;

        function format(d) {
            raw = d.raw;
            return `<tr><td class="tx-13 pd-0">
                        <nav class="d-flex">
                            ${d.raw.subdivisions.map((item, index) => {
                return (`<tr><td class="tx-13 pd-0"><nav class="d-flex">${index+1}. ${item.name} (${item.products[0].name} - ${item.products[0].property.name}) : ${auth.user.country.currency.symbol}${item.products[0].amount}<a href="#" id="${item._id}-${d.raw._id}" class="nav-link pd-t-0 edit-subdivision"><i class="fa fa-map"></i> View Map</a></nav></td></tr>`)
            })}`;
        }

        table.on('click', '.edit-subdivision', function (e) {
            e.preventDefault();

            let data = raw.subdivisions.find((data) => data._id == e.target.id.split('-')[0]);

            coordinate = convertCoordinates(data);

            $("#zone-name").text(`${data.name} Zone`);
            $("#modalZone").modal({ backdrop: 'static', focus: false, show: true });
            setOpenMap(true);
        });

        return () => {
            table.destroy();
        }
    }, []);

    const reloadTable = () => {
        $('#zone_table').DataTable().ajax.reload(null, false);
    };

    const convertCoordinates = (document) => {
        const coordinates = document.geometry.coordinates[0];
        return coordinates.map(coord => ({
            lat: coord[0],
            lng: coord[1]
        }));
    };

    const resetForm = (id) => {
        $(`#${id}`).modal("hide");
        setOpenMap(false);
        coordinate = null;
        $(".modal-backdrop").remove();
    };

    return (
        <div className="content-body">
            <div className="container-fluid pd-x-0">
                <div className="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-25 mg-xl-b-30">
                    <div>
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb breadcrumb-style1 mg-b-10">
                                <li className="breadcrumb-item"><a href="#">Dashboard</a></li>
                                <li className="breadcrumb-item active" aria-current="page">Zones</li>
                            </ol>
                        </nav>
                        <h4 className="mg-b-0 tx-spacing--1">Zones</h4>
                    </div>
                </div>

                <div className="row row-xs">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="card-header d-flex align-items-center justify-content-between">
                                <h6 className="mg-b-0">Zone List</h6>
                                <div className="d-flex tx-18">
                                    <Link to="#" onClick={reloadTable.bind()} className="link-03 lh-0"><i className="icon ion-md-refresh"></i></Link>
                                </div>
                            </div>
                            <div className="card-body table-responsive">
                                <table id="zone_table" className="table" style={{width: "100%"}}>
                                    <thead>
                                    <tr>
                                        <th></th>
                                        <th className="wd-25p">Name</th>
                                        <th className="wd-25p">Status</th>
                                        <th className="wd-25p">Date Created</th>
                                        <th className="wd-25p">Action</th>
                                    </tr>
                                    </thead>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal fade" id="modalZone" tabIndex="-1" role="dialog" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                        <div className="modal-content">
                            <div className="modal-header pd-y-20 pd-x-20 pd-sm-x-30">
                                <a href="#" role="button" className="close pos-absolute t-15 r-15" data-dismiss="modal"
                                   aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </a>
                                <div className="media-body"><a href="#" role="button" className="close pos-absolute t-15 r-15" data-dismiss="modal" onClick={resetForm.bind(null, "modalZone")} aria-label="Close"><span aria-hidden="true">×</span></a>
                                    <h4 id="zone-name" className="tx-18 tx-sm-20 mg-b-2">View Zone</h4>
                                    <p className="tx-13 tx-color-02 mg-b-0">Zone information.</p>
                                </div>
                            </div>
                            <div className="modal-body pd-sm-t-30 pd-sm-b-40 pd-sm-x-30">
                                <div className="mg-b-20">
                                    {openMap ? <GMap coordinate={coordinate}/> : null}
                                </div>

                                <button className="btn btn-success btn-block" onClick={resetForm.bind(null, "modalZone")}>Close</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default Zone;
