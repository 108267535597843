import axios from "axios";
import {
    SUBURB_URL,
    STATE_URL,
    CITY_URL,
    COUNTRY_URL,
    PRODUCT_URL,
    GET_PRE_SIGNED_URL,
    NOTIFICATION_URL,
    TERRITORY_URL,
    COLLECTOR_URL,
    AGENT_URL,
    USER_URL,
    GET_COLLECTOR_URL,
    GET_SCHEDULES_URL,
    GET_RATING_URL,
    ACCOUNT_URL,
    GET_ZONE_URL,
    GET_FINANCE_REPORT_URL,
    GET_USER_URL,
    UAC_URL,
    WASTE_URL,
    GET_USER_REPORT_URL,
    GET_WASTE_REPORT_URL,
    GET_GENERAL_REPORT_URL,
    GET_COLLECTION_WASTE_REPORT_URL,
    GET_REVENUE_REPORT_URL
} from '../api';
import errorHandler from "../utils/errorHandler";

export const region = (method, data) => {
    return axios({method: method, url: SUBURB_URL, data: data}).then((response) => {
        return {
            payload: response.data.message,
            error: false
        }
    }).catch((error) => {
        errorHandler(error, "top-right");
        return {
            payload: null,
            error: true
        }
    });
};

export const state = (method, data) => {
    return axios({method: method, url: STATE_URL, data: data}).then((response) => {
        return {
            payload: response.data.message,
            error: false
        }
    }).catch((error) => {
        errorHandler(error, "top-right");
        return {
            payload: null,
            error: true
        }
    });
};

export const country = (method, data) => {
    return axios({method: method, url: COUNTRY_URL, data: data}).then((response) => {
        return {
            payload: response.data.message,
            error: false
        }
    }).catch((error) => {
        errorHandler(error, "top-right");
        return {
            payload: null,
            error: true
        }
    });
};

export const city = (method, data) => {
    return axios({method: method, url: CITY_URL, data: data}).then((response) => {
        return {
            payload: response.data.message,
            error: false
        }
    }).catch((error) => {
        errorHandler(error, "top-right");
        return {
            payload: null,
            error: true
        }
    });
};

export const suburb = (method, data) => {
    return axios({method: method, url: SUBURB_URL, data: data}).then((response) => {
        return {
            payload: response.data.message,
            error: false
        }
    }).catch((error) => {
        errorHandler(error, "top-right");
        return {
            payload: null,
            error: true
        }
    });
};

export const product = (method, data) => {
    return axios({method: method, url: PRODUCT_URL, data: data}).then((response) => {
        return {
            payload: response.data.message,
            error: false
        }
    }).catch((error) => {
        errorHandler(error, "top-right");
        return {
            payload: null,
            error: true
        }
    });
};

export const agent = (method, data) => {
    return axios({method: method, url: AGENT_URL, data: data}).then((response) => {
        return {
            payload: response.data.message,
            error: false
        }
    }).catch((error) => {
        errorHandler(error, "top-right");
        return {
            payload: null,
            error: true
        }
    });
};

export const account = (method, data) => {
    return axios({method: method, url: ACCOUNT_URL, data: data}).then((response) => {
        return {
            payload: response.data,
            error: false
        }
    }).catch((error) => {
        errorHandler(error, "top-right");
        return {
            payload: null,
            error: true
        }
    });
};

export const getFinanceReport = () => {
    return axios.get(GET_FINANCE_REPORT_URL).then((response) => {
        return {
            payload: response.data,
            error: false
        };
    }).catch((error) => {
        errorHandler(error, "top-right");
        return {
            payload: error,
            error: true
        }
    });
}


export const user = (method, data) => {
    return axios({method: method, url: USER_URL, data: data}).then((response) => {
        return {
            payload: response.data.message,
            error: false
        }
    }).catch((error) => {
        errorHandler(error, "top-right");
        return {
            payload: null,
            error: true
        }
    });
};

export const admin = (method, data) => {
    return axios({method: method, url: UAC_URL, data: data}).then((response) => {
        return {
            payload: response.data.payload,
            error: false
        }
    }).catch((error) => {
        errorHandler(error, "top-right");
        return {
            payload: null,
            error: true
        }
    });
}

export const getZones = () => {
    return axios.get(GET_ZONE_URL).then((response) => {
        return {
            payload: response.data.payload,
            error: false
        }
    }).catch((error) => {
        errorHandler(error, "top-right");
        return {
            payload: null,
            error: true
        }
    });
};

export const getRevenueReport = () => {
    return axios.get(GET_REVENUE_REPORT_URL).then((response) => {
        return {
            payload: response.data,
            error: false
        }
    }).catch((error) => {
        errorHandler(error, "top-right");
        return {
            payload: null,
            error: true
        }
    });
};

export const getSchedules = () => {
    return axios.get(GET_SCHEDULES_URL).then((response) => {
        return {
            payload: response.data.payload,
            error: false
        }
    }).catch((error) => {
        errorHandler(error, "top-right");
        return {
            payload: null,
            error: true
        }
    });
};

export const getRating = () => {
    return axios.get(GET_RATING_URL).then((response) => {
        return {
            payload: response.data,
            error: false
        }
    }).catch((error) => {
        errorHandler(error, "top-right");
        return {
            payload: null,
            error: true
        }
    });
};

export const territory = (method, data) => {
    return axios({method: method, url: TERRITORY_URL, data: data}).then((response) => {
        return {
            payload: response.data.message,
            error: false
        }
    }).catch((error) => {
        errorHandler(error, "top-right");
        return {
            payload: null,
            error: true
        }
    });
};

export const waste = (method, data) => {
    return axios({method: method, url: WASTE_URL, data: data}).then((response) => {
        return {
            payload: response.data.message,
            error: false
        }
    }).catch((error) => {
        errorHandler(error, "top-right");
        return {
            payload: null,
            error: true
        }
    });
};

export const getCollector = (id) => {
    return axios.get(GET_COLLECTOR_URL(id)).then((response) => {
        return {
            payload: response.data.payload,
            error: false
        }
    }).catch((error) => {
        errorHandler(error, "top-right");
        return {
            payload: null,
            error: true
        }
    });
}

export const getUser = (id) => {
    return axios.get(GET_USER_URL(id)).then((response) => {
        return {
            payload: response.data.payload,
            error: false
        }
    }).catch((error) => {
        errorHandler(error, "top-right");
        return {
            payload: null,
            error: true
        }
    });
}
export const collector = (method, data) => {
    return axios({method: method, url: COLLECTOR_URL, data: data}).then((response) => {
        return {
            payload: response.data.payload,
            error: false
        }
    }).catch((error) => {
        errorHandler(error, "top-right");
        return {
            payload: null,
            error: true
        }
    });
}

export const notification = (method, data) => {
    return axios({method: method, url: NOTIFICATION_URL, data: data}).then((response) => {
        return {
            payload: response.data.payload,
            error: false
        }
    }).catch((error) => {
        errorHandler(error, "top-right");
        return {
            payload: null,
            error: true
        }
    });
}

export const getPreSignedUrl = () => {
    return axios.get(GET_PRE_SIGNED_URL).then((response) => {
        return {
            error: false,
            payload: response.data
        }
    }).catch((error) => {
        errorHandler(error, "top-right");
        return {
            payload: error,
            error: true
        }
    });
}

export const uploadPhoto = (signed_url, binary, config) => {
    return axios.put(signed_url, binary, config).then((response) => {
        return {
            payload: response,
            error: false
        }
    }).catch((error) => {
        errorHandler(error, "top-right");
        return {
            payload: error,
            error: true
        }
    });
}

export const getUserReport = () => {
    return axios.get(GET_USER_REPORT_URL).then((response) => {
        return {
            payload: response.data,
            error: false
        };
    }).catch((error) => {
        errorHandler(error, "top-right");
        return {
            payload: error,
            error: true
        }
    });
};

export const getWasteReport = (geometry) => {
    console.log(geometry)
    return axios.post(GET_WASTE_REPORT_URL, geometry).then((response) => {
        return {
            payload: response.data,
            error: false
        };
    }).catch((error) => {
        errorHandler(error, "top-right");
        return {
            payload: error,
            error: true
        }
    });
}

export const getCollectionWasteReport = (date) => {
    return axios.post(GET_COLLECTION_WASTE_REPORT_URL, {date: new Date(date)}).then((response) => {
        return {
            payload: response.data,
            error: false
        };
    }).catch((error) => {
        errorHandler(error, "top-right");
        return {
            payload: error,
            error: true
        }
    });
}

export const getGeneralReport = () => {
    return axios.post(GET_GENERAL_REPORT_URL).then((response) => {
        return {
            payload: response.data,
            error: false
        };
    }).catch((error) => {
        errorHandler(error, "top-right");
        return {
            payload: error,
            error: true
        }
    });
}

export const searchOrderNumber = () => {

}
