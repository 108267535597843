import {createSlice} from '@reduxjs/toolkit';

const slice = createSlice({
    name: "app",
    initialState: {
        territories: [],
        settings: {}
    },
    reducers: {
        //actions => action handlers
        SET_TERRITORIES: (state, action) => {
            return {
                ...state,
                territories: action.payload.territories
            };
        }
    }
});

export const {SET_TERRITORIES} = slice.actions;
export default slice.reducer;
