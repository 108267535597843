import React, {useEffect} from 'react';
import $ from "jquery";
import {capitalize} from "../../utils/capitalize";
import cogoToast from "cogo-toast";
import {getSchedules} from "../../api/app";
import "components-jqueryui";
import moment from "moment/moment";
import "fullcalendar";
import {GET_SCHEDULES_URL} from "../../api";

var curYear = moment().format('YYYY');
var curMonth = moment().format('MM');

let events = [];

const colorSets = [
    { backgroundColor: '#d9e8ff', borderColor: '#0168fa' },
    { backgroundColor: '#c3edd5', borderColor: '#10b759' },
    { backgroundColor: '#fcbfdc', borderColor: '#f10075' },
    { backgroundColor: '#bff2f2', borderColor: '#00cccc' },
    { backgroundColor: '#ffdec4', borderColor: '#fd7e14' },
    { backgroundColor: '#ffe5b9', borderColor: '#ffb703' },
    { backgroundColor: '#d6f5e3', borderColor: '#34d399' },
    { backgroundColor: '#fde2e2', borderColor: '#f87171' },
    { backgroundColor: '#e0e7ff', borderColor: '#6366f1' }
];

const Schedule = () => {

    document.title = "Schedule";

    useEffect(() => {
        getSchedules().then((response) => {
           updateData(response.payload)
        })
    }, []);

    useEffect(() => {
        $('#calendar').fullCalendar({
            height: 'parent',
            header: {
                left: 'prev,next today',
                center: 'title',
                right: 'month,agendaWeek,agendaDay,listWeek'
            },
            navLinks: true,
            selectable: true,
            selectLongPressDelay: 100,
            editable: true,
            nowIndicator: true,
            defaultView: 'listMonth',
            views: {
                agenda: {
                    columnHeaderHtml: function(mom) {
                        return '<span>' + mom.format('ddd') + '</span>' +
                            '<span>' + mom.format('DD') + '</span>';
                    }
                },
                day: { columnHeader: false },
                listMonth: {
                    listDayFormat: 'ddd DD',
                    listDayAltFormat: false
                },
                listWeek: {
                    listDayFormat: 'ddd DD',
                    listDayAltFormat: false
                },
                agendaThreeDay: {
                    type: 'agenda',
                    duration: { days: 3 },
                    titleFormat: 'MMMM YYYY'
                }
            },
            // eventSources: [calender],
            eventSources: [events],
            eventAfterAllRender: function(view) {
                if(view.name === 'listMonth' || view.name === 'listWeek') {
                    var dates = view.el.find('.fc-list-heading-main');
                    dates.each(function(){
                        var text = $(this).text().split(' ');
                        var now = moment().format('DD');

                        $(this).html(text[0]+'<span>'+text[1]+'</span>');
                        if(now === text[1]) { $(this).addClass('now'); }
                    });
                }
            },
            eventRender: function(event, element) {

                if(event.description) {
                    element.find('.fc-list-item-title').append('<span class="fc-desc">' + event.description + '</span>');
                    element.find('.fc-content').append('<span class="fc-desc">' + event.description + '</span>');
                }

                var eBorderColor = (event.source.borderColor)? event.source.borderColor : event.borderColor;
                element.find('.fc-list-item-time').css({
                    color: eBorderColor,
                    borderColor: eBorderColor
                });

                element.find('.fc-list-item-title').css({
                    borderColor: eBorderColor
                });

                element.css('borderLeftColor', eBorderColor);
            },
        });

        var calendar = $('#calendar').fullCalendar('getCalendar');

        // change view to week when in tablet
        if(window.matchMedia('(min-width: 576px)').matches) {
            calendar.changeView('agendaWeek');
        }

        // change view to month when in desktop
        if(window.matchMedia('(min-width: 992px)').matches) {
            calendar.changeView('month');
        }

        // change view based in viewport width when resize is detected
        calendar.option('windowResize', function(view) {
            if(view.name === 'listWeek') {
                if(window.matchMedia('(min-width: 992px)').matches) {
                    calendar.changeView('month');
                } else {
                    calendar.changeView('listWeek');
                }
            }
        });

        // Display calendar event modal
        calendar.on('eventClick', function(calEvent, jsEvent, view){

            var modal = $('#modalCalendarEvent');

            modal.modal('show');
            modal.find('.event-title').text(calEvent.title);

            if(calEvent.description) {
                modal.find('.event-desc').text(calEvent.description);
                modal.find('.event-desc').prev().removeClass('d-none');
            } else {
                modal.find('.event-desc').text('');
                modal.find('.event-desc').prev().addClass('d-none');
            }

            modal.find('.event-start-date').text(moment(calEvent.start).format('LLL'));
            modal.find('.event-end-date').text(moment(calEvent.end).format('LLL'));

            //styling
            modal.find('.modal-header').css('backgroundColor', (calEvent.source.borderColor)? calEvent.source.borderColor : calEvent.borderColor);
        });

        // display current date
        var dateNow = calendar.getDate();
        calendar.option('select', function(startDate, endDate){
            $('#modalCreateEvent').modal('show');
            $('#eventStartDate').val(startDate.format('LL'));
            $('#eventEndDate').val(endDate.format('LL'));

            $('#eventStartTime').val(startDate.format('LT')).trigger('change');
            $('#eventEndTime').val(endDate.format('LT')).trigger('change');
        });

        $('.select2-modal').select2({
            minimumResultsForSearch: Infinity,
            dropdownCssClass: 'select2-dropdown-modal',
        });

        // $('.calendar-add').on('click', function(e){
        //     e.preventDefault()
        //
        //     $('#modalCreateEvent').modal('show');
        // });
    }, []);

    const getRandomColorSet = () => {
        const randomIndex = Math.floor(Math.random() * colorSets.length);
        return colorSets[randomIndex];
    };

    const updateData = (data) => {
        let array = [];

        const getDatesForDayInMonth = (dayName, month, year) => {
            const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
            let result = [];
            let dayIndex = daysOfWeek.indexOf(dayName);

            for (let day = 1; day <= 31; day++) {
                let currentDate = new Date(year, month, day);
                if (currentDate.getMonth() !== month) break;
                if (currentDate.getDay() === dayIndex) {
                    result.push(currentDate);
                }
            }

            return result;
        };

        let curYear = new Date().getFullYear();
        let curMonth = new Date().getMonth();

        data.map((item) => {
            if (item.days && item.days.length > 0) {
                let dayName = item.days[0];
                let datesForDay = getDatesForDayInMonth(dayName, curMonth, curYear);

                datesForDay.forEach((date) => {
                    let startDate = moment(date).set({ hour: 8, minute: 0 });
                    let endDate = moment(date).set({ hour: 18, minute: 0 });

                    const { backgroundColor, borderColor } = getRandomColorSet();

                    array.push({
                        id: item._id,
                        title: item.name || "No Title",
                        start: startDate.format(),
                        end: endDate.format(),
                        description: item.description || "No Description",
                        backgroundColor: backgroundColor,
                        borderColor: borderColor,
                    });
                });
            }
        });

        $('#calendar').fullCalendar('removeEvents');
        $('#calendar').fullCalendar('addEventSource', array);
        $('#calendar').fullCalendar('rerenderEvents');
    }

    return (
        <div className="content-body pd-0">
            <div id="calendar" className="calendar-content-body"></div>

            {/*<div className="modal calendar-modal-create fade effect-scale" id="modalCreateEvent" role="dialog"*/}
            {/*     aria-hidden="true">*/}
            {/*    <div className="modal-dialog modal-dialog-centered" role="document">*/}
            {/*        <div className="modal-content">*/}
            {/*            <div className="modal-body pd-20 pd-sm-30">*/}
            {/*                <button type="button" className="close pos-absolute t-20 r-20" data-dismiss="modal"*/}
            {/*                        aria-label="Close">*/}
            {/*                    <span aria-hidden="true"><i data-feather="x"></i></span>*/}
            {/*                </button>*/}

            {/*                <h5 className="tx-18 tx-sm-20 mg-b-20 mg-sm-b-30">Create New Event</h5>*/}

            {/*                <form id="formCalendar" method="post" action="app-calendar.html">*/}
            {/*                    <div className="form-group">*/}
            {/*                        <input type="text" className="form-control" placeholder="Add title"/>*/}
            {/*                    </div>*/}

            {/*                    <div className="form-group d-flex align-items-center">*/}
            {/*                        <div className="custom-control custom-radio">*/}
            {/*                            <input type="radio" id="customRadio1" name="customRadio"*/}
            {/*                                   className="custom-control-input" checked/>*/}
            {/*                            <label className="custom-control-label" htmlFor="customRadio1">Event</label>*/}
            {/*                        </div>*/}
            {/*                        <div className="custom-control custom-radio mg-l-20">*/}
            {/*                            <input type="radio" id="customRadio2" name="customRadio"*/}
            {/*                                   className="custom-control-input" checked/>*/}
            {/*                            <label className="custom-control-label" htmlFor="customRadio2">Reminder</label>*/}
            {/*                        </div>*/}
            {/*                    </div>*/}

            {/*                    <div className="form-group mg-t-30">*/}
            {/*                        <label className="tx-uppercase tx-sans tx-11 tx-medium tx-spacing-1 tx-color-03">Start*/}
            {/*                            Date</label>*/}
            {/*                        <div className="row row-xs">*/}
            {/*                            <div className="col-7">*/}
            {/*                                <input id="eventStartDate" type="text" value="" className="form-control"*/}
            {/*                                       placeholder="Select date"/>*/}
            {/*                            </div>*/}

            {/*                            <div className="col-5">*/}
            {/*                                <select className="custom-select">*/}
            {/*                                    <option selected>Select Time</option>*/}
            {/*                                </select>*/}
            {/*                            </div>*/}
            {/*                        </div>*/}
            {/*                    </div>*/}

            {/*                    <div className="form-group">*/}
            {/*                        <label className="tx-uppercase tx-sans tx-11 tx-medium tx-spacing-1 tx-color-03">End*/}
            {/*                            Date</label>*/}
            {/*                        <div className="row row-xs">*/}
            {/*                            <div className="col-7">*/}
            {/*                                <input id="eventEndDate" type="text" value="" className="form-control"*/}
            {/*                                       placeholder="Select date"/>*/}
            {/*                            </div>*/}

            {/*                            <div className="col-5">*/}
            {/*                                <select className="custom-select">*/}
            {/*                                    <option selected>Select Time</option>*/}
            {/*                                </select>*/}
            {/*                            </div>*/}
            {/*                        </div>*/}
            {/*                    </div>*/}

            {/*                    <div className="form-group">*/}
            {/*                        <textarea className="form-control" rows="2"*/}
            {/*                                  placeholder="Write some description (optional)"></textarea>*/}
            {/*                    </div>*/}

            {/*                </form>*/}
            {/*            </div>*/}

            {/*            <div className="modal-footer">*/}
            {/*                <button type="submit" className="btn btn-primary mg-r-5">Add Event</button>*/}
            {/*                <a href="" className="btn btn-secondary" data-dismiss="modal">Discard</a>*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*</div>*/}

            <div className="modal calendar-modal-event fade effect-scale" id="modalCalendarEvent" role="dialog"
                 aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h6 className="event-title"></h6>
                            {/*<nav className="nav nav-modal-event">*/}
                            {/*    <a href="#" className="nav-link"><i data-feather="external-link"></i></a>*/}
                            {/*    <a href="#" className="nav-link"><i data-feather="trash-2"></i></a>*/}
                            {/*    <a href="#" className="nav-link" data-dismiss="modal"><i data-feather="x"></i></a>*/}
                            {/*</nav>*/}
                        </div>

                        <div className="modal-body">
                            <div className="row row-sm">
                                <div className="col-sm-6">
                                    <label className="tx-uppercase tx-sans tx-11 tx-medium tx-spacing-1 tx-color-03">Start Date</label>
                                    <p className="event-start-date"></p>
                                </div>
                                <div className="col-sm-6">
                                    <label className="tx-uppercase tx-sans tx-11 tx-medium tx-spacing-1 tx-color-03">End Date</label>
                                    <p className="event-end-date"></p>
                                </div>
                            </div>

                            <label className="tx-uppercase tx-sans tx-11 tx-medium tx-spacing-1 tx-color-03">Description</label>
                            <p className="event-desc tx-gray-900 mg-b-40"></p>

                            <a href="" className="btn btn-secondary pd-x-20" data-dismiss="modal">Close</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default Schedule;
