import React, {useEffect, useState} from 'react';
import 'datatables.net';
import 'datatables.net-responsive';
import {GET_COLLECTION_DATA} from '../../../api';
import $ from "jquery";
import {capitalize} from "../../../utils/capitalize";
import moment from 'moment';
import formatNumber from "../../../utils/formatNumber";
import {getZones, getWasteReport} from "../../../api/app";
import cogoToast from "cogo-toast";
import {Link} from "react-router-dom";
import DateRangePicker from "../../../components/common/DatePicker";
import "jquery-steps/build/jquery.steps";
const jQuery = window.$;

let selectedArea = null;
let currentDateRange = null;
let type = null;

const Subscription = (props) => {

    document.title = "Subscription";

    const [activeLink, setActiveLink] = useState('All');
    const [zones, setZones] = useState([]);
    const [subdivisions, setSubdivisions] = useState([]);
    const [areaName, setAreaName] = useState("All Zones");
    const [subdivisionName, setSubdivisionName] = useState("Select");
    const token = localStorage.getItem('jwtToken');
    const [loading, setLoading] = useState(true);
    const [selectedDate, setSelectedDate] = useState(false);
    const [currDateRange, setCurrDateRange] = useState(null);

    useEffect(() => {
        getWasteReport(selectedArea?.geometry ? selectedArea.geometry : {}).then((response) => {
            if(!response.error) {
                $('#total-waste').text(formatNumber(response.payload.total_wastes));
                $('#total-paid').text(response.payload.total_paid);
                $('#total-expired').text(response.payload.total_expired);
                $('#total-cancelled').text(response.payload.total_cancelled);
            }
        });
    }, [selectedArea?.geometry]);

    useEffect(() => {
        getZones().then((response) => {
            if(!response.error) {
                setZones(response.payload);
                setLoading(false);
            }
        })
    }, []);

    useEffect(() => {
        let table = $('#td-subscription').DataTable({
            responsive: true,
            "language": {
                "processing": '<div class="spinner-border"></div><p class="mg-t-10 tx-12">Collecting Subscription Report</p>'
            },
            searching: true,
            lengthMenu: [[10], [10]],
            ordering: false,
            info: true,
            bFilter: false,
            processing: true,
            pageLength: 10,
            serverSide: true,
            ajax: function(data, callback) {
                // make a regular ajax request using data.start and data.length
                $.ajax(GET_COLLECTION_DATA, {
                    type: 'POST',
                    headers: {
                        "Authorization": token
                    },
                    data: {
                        query: $('.dataTables_filter input').val(),
                        collection: "waste",
                        fieldname: "type",
                        pageSize: data.start,
                        filter: currentDateRange !== null ? {
                            type: "subscription",
                            status: type === null ? {} : type,
                            "address.location": { $geoWithin: { $geometry: selectedArea?.geometry }},
                            "date.iso":
                                {
                                    $gte: currentDateRange.start,
                                    $lte: currentDateRange.end
                                }
                        } : {
                            type: "subscription",
                            status: type === null ? {} : type,
                            "address.location": { $geoWithin: { $geometry: selectedArea?.geometry } }
                        },
                        format: 'json',
                        populate: [
                            {
                                path: 'collector',
                                model: 'collector',
                                populate: {
                                    path: 'country',
                                    model: 'country'
                                }

                            },
                            {
                                path: 'user',
                                model: 'user',
                            },
                            {
                                path: 'product',
                                model: 'product',
                            },
                            {
                                path: 'address.property',
                                model: 'property',
                            }
                        ],
                        pageIndex: (data.length + data.start) / data.length
                    },
                    success: function (res) {
                        let array = [];
                        res.data.map((data) => {
                            array.push({raw: data})
                        });
                        callback({
                            recordsTotal: res.total,
                            recordsFiltered: res.total,
                            data: array
                        });
                    },
                    error: function(err) {
                        cogoToast.error( err.responseJSON['error'].details !== undefined ? err.responseJSON['error'].details[0].message : err.responseJSON['error'], {position: "top-right", hideAfter: 5});
                    }
                });
            },
            "columns": [
                {
                    className: 'dt-control',
                    orderable: false,
                    data: null,
                    defaultContent: '',
                },
                {"data": "raw"},
                {"data": "raw"},
                {"data": "raw"},
                {"data": "raw"},
                {"data": "raw"}
            ],
            "columnDefs": [
                {
                    "render": function () {
                        return null;
                    },
                    "targets": 0
                },
                {
                    "render": function (data) {
                        return `${capitalize(data.user.first_name)} ${capitalize(data.user.last_name)}`;
                    },
                    "targets": 1
                },
                {
                    "render": function (data) {
                        return data.user.mobile_number
                    },
                    "targets": 2
                },
                {
                    "render": function (data) {
                        return {
                            subscription : `<span class="badge badge-primary tx-white">Subscription</span>`,
                            pickup : `<span class="badge badge-secondary tx-white">Pickup</span>`
                        }[data.product.type];
                    },
                    "targets": 3
                },
                {
                    "render": function (data) {
                        return capitalize(data.address.meta.suburb);
                    },
                    "targets": 4
                },
                {
                    "render": function (data) {
                        return data.status !== "pending" ? data.due_date.str > moment(new Date()).format("YYYY/MM/DD hh:mm") ? `<span class="tx-purple">${moment(data.due_date.iso).format("Do MMM, YYYY")}</span>` : `<span class="tx-danger">${moment(data.due_date.iso).format("Do MMM, YYYY")}</span>` : `<span class="badge badge-warning tx-white">Pending</span>`;
                    },
                    "targets": 5
                }
            ]
        });

        table.on('click', '.view_user', function(e) {
            e.preventDefault();
            let extract_td = table.row($(this).closest('tr')).data();
            props.history.push(`/user/${extract_td.raw._id}`);
        });

        $('#td-subscription tbody').on('click', 'td.dt-control', function () {
            let tr = $(this).closest('tr');
            let row = table.row(tr);

            if (row.child.isShown()) {
                // This row is already open - close it
                row.child.hide();
                tr.removeClass('shown');
            } else {
                // Open this row
                row.child(format(row.data())).show();
                tr.addClass('shown');
            }
        });

        function format(d) {
            // `d` is the original data object for the row
            return (
                '<table cellpadding="5" cellspacing="0" border="0" style="padding-left:50px;">' +
                '<tr>' +
                '<td>Total Amount:</td>' +
                '<td>' +
                d.raw.collector.country.currency.symbol + d.raw.meta.amount*d.raw.total_month*d.raw.bin +
                '</td>' +
                '</tr>' +
                '<tr>' +
                '<td>Number of Bin:</td>' +
                '<td>' +
                d.raw.bin +
                '</td>' +
                '</tr>' +
                '<tr>' +
                '<td>Address:</td>' +
                '<td>' +
                `${d.raw.address.unit_number}, ${d.raw.address.meta.street_address}` +
                '</td>' +
                '</tr>' +
                '<tr>' +
                '<td>Type:</td>' +
                '<td>' +
                capitalize(d.raw.address.property.type) +
                '</td>' +
                '</tr>' +
                '<tr>' +
                '<td>Date Issued:</td>' +
                '<td>'+moment(d.raw.date.iso).format("Do MMM, YYYY")+'</td>' +
                '</tr>' +
                '</table>'
            );
        }

        return () => {
            table.destroy();
        }

    }, []);

    const modalFilter = () => {

    }

    const onAreaSelected = (area)  => {
        area !== null ? selectedArea = area : selectedArea = null;
        area !== null ? setAreaName(area.name) : setAreaName("All Zones");
        area !== null ? setSubdivisions(area.subdivisions) : setSubdivisions([]);
        reloadTable();
    }

    const onSubdivisionSelected = (area)  => {
        area !== null ? selectedArea = area : selectedArea = null;
        area !== null ? setSubdivisionName(area.name) : setSubdivisionName("Select");
        reloadTable();
    }

    const refreshTable = (e) => {
        e.preventDefault();
        setSelectedDate(false);
        setCurrDateRange(null);
        currentDateRange = null;
        $('#td-subscription').DataTable().ajax.reload(null, false);
    }

    const handleDateRangeSelect = (range) => {
        const formattedStartDate = $.datepicker.formatDate('dd/mm/yy', range.startDate);
        const formattedEndDate = $.datepicker.formatDate('dd/mm/yy', range.endDate);

        currentDateRange = {
            start: moment(formattedStartDate, 'DD/MM/YYYY').format("YYYY/MM/DD 00:00:00"),
            end: moment(formattedEndDate, 'DD/MM/YYYY').format("YYYY/MM/DD 23:59:59")
        };

        setCurrDateRange({
            start: moment(formattedStartDate, 'DD/MM/YYYY').format("YYYY/MM/DD 00:00:00"),
            end: moment(formattedEndDate, 'DD/MM/YYYY').format("YYYY/MM/DD 23:59:59")
        });

        setSelectedDate(true);

        reloadTable();
    };

    const reloadTable = () => {
        $('#td-subscription').DataTable().ajax.reload(null, false);
    };

    const handleNavClick = (linkName) => {
        setActiveLink(linkName);
        type = linkName === "All" ? null : linkName.toLowerCase();
        reloadTable();
    };

    const handleShowModal = () => {
        jQuery('#wizard2').steps({
            headerTag: 'h3',
            bodyTag: 'section',
            autoFocus: true,
            titleTemplate: '<span class="number">#index#</span> <span class="title">#title#</span>',
            onStepChanging: function (event, currentIndex, newIndex) {
                if(currentIndex < newIndex) {
                    // Step 1 form validation
                    if(currentIndex === 0) {
                        let customer = $('#customer').parsley();
                        let total_month = $('#total-month').parsley();
                        let total_bin = $('#total-bin').parsley();

                        if(customer.isValid() && total_month.isValid() && total_bin.isValid()) {
                            return true;
                        } else {
                            customer.validate();
                            total_month.validate();
                            total_bin.validate();
                        }
                    }

                    // Step 2 form validation
                    if(currentIndex === 1) {
                        let email = $('#email').parsley();
                        if(email.isValid()) {
                            return true;
                        } else { email.validate(); }
                    }
                    // Always allow step back to the previous step even if the current step is not valid.
                } else { return true; }
            }
        });

        $("#modalSubscription").modal({ backdrop: 'static', focus: false, show: true });
    };

    const resetForm = (id, form) => {
        $(`#${id}`).modal("hide");
        $('#wizard2 input, #wizard2 select').each(function() {
            if ($(this).parsley()) {
                $(this).parsley().destroy();
            }
        });
        jQuery('#wizard2').steps('destroy');
        $(".modal-backdrop").remove();
    };

    return (
        <div className="content-body">
            <div className="container-fluid pd-x-0">
                <div className="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-25 mg-xl-b-30">
                    <div>
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb breadcrumb-style1 mg-b-10">
                                <li className="breadcrumb-item active" aria-current="page">Wastes</li>
                            </ol>
                        </nav>
                        <h4 className="mg-b-0 tx-spacing--1">Subscriptions</h4>
                    </div>
                    <div className="d-none d-md-block d-flex">
                        <button className="btn btn-sm pd-x-15 btn-primary btn-uppercase mg-l-5"><i className="wd-10 mg-r-5 fa fa-upload"></i> Export</button>
                        {/*<button className="btn btn-sm pd-x-15 btn-success btn-uppercase mg-l-5 outline-none" onClick={handleShowModal}><i className="wd-10 mg-r-5 fa fa-plus"></i> Add New</button>*/}
                        {loading ? <button disabled className="btn btn-sm pd-x-15 btn-outline-secondary btn-uppercase mg-l-5 outline-none"><span className="spinner-border spinner-border-sm mg-r-10" role="status" aria-hidden="true"/> Collecting</button> :
                            <span className="dropdown">
                                <button className="btn btn-sm pd-x-15 btn-outline-secondary btn-uppercase mg-l-5 outline-none dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><i className="wd-10 mg-r-5 fa fa-globe"></i> {areaName}</button>
                                <div className="dropdown-menu" style={{maxHeight: 356, overflowY: "auto"}}>
                                    <a href="#" onClick={(e) => {e.preventDefault(); onAreaSelected(null)}} className="dropdown-item">All Zones</a>
                                    {zones.map((item, index) => {
                                        return(
                                            <a href="#" key={index} onClick={(e) => {e.preventDefault(); onAreaSelected(item)}} className="dropdown-item">{capitalize(item.name)}</a>
                                        )
                                    })}
                                </div>
                            </span> }
                        <span className="dropdown">
                                <button className="btn btn-sm pd-x-15 btn-secondary btn-uppercase mg-l-5 outline-none dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><i className="wd-10 mg-r-5 fa fa-globe"></i> {subdivisions?.length ? subdivisionName : "Select"}</button>
                                <div className="dropdown-menu" style={{maxHeight: 356, overflowY: "auto"}}>
                                    {subdivisions?.length ? subdivisions.map((item, index) => {
                                        return(
                                            <a href="#" key={index} onClick={(e) => {e.preventDefault(); onSubdivisionSelected(item)}} className="dropdown-item">{capitalize(item.name)}</a>
                                        )
                                    }) : <span className="dropdown-item">Select Zone First</span>}
                                </div>
                            </span>
                    </div>
                </div>

                <div className="row row-xs">
                    <div className="col-sm-6 col-lg-3 mg-t-10 mg-lg-t-0">
                        <div className="card card-body">
                            <h6 className="tx-uppercase tx-11 tx-spacing-1 tx-primary tx-semibold mg-b-8">Total Subscriptions</h6>
                            <div className="d-flex d-lg-block d-xl-flex align-items-end">
                                <h3 id="total-waste" className="tx-normal tx-rubik mg-b-0 mg-r-5 lh-1">0</h3>
                            </div>
                            <span id="date-current" className="tx-11 tx-color-02 mg-b-0 mg-t-5">Total All Subscriptions</span>
                        </div>
                    </div>

                    <div className="col-sm-6 col-lg-3 mg-t-10 mg-sm-t-0">
                        <div className="card card-body">
                            <h6 className="tx-uppercase tx-11 tx-spacing-1 tx-success tx-semibold mg-b-8">Total Paid Subscription</h6>
                            <div className="d-flex d-lg-block d-xl-flex align-items-end">
                                <h3 id="total-paid" className="tx-normal tx-rubik mg-b-0 mg-r-5 lh-1">0</h3>
                            </div>
                            <span id="month-current" className="tx-11 tx-color-02 mg-b-0 mg-t-5">Number of Paid Subscription</span>
                        </div>
                    </div>

                    <div className="col-sm-6 col-lg-3 mg-t-10 mg-sm-t-0">
                        <div className="card card-body">
                            <h6 className="tx-uppercase tx-11 tx-spacing-1 tx-warning tx-semibold mg-b-8">Total Owning</h6>
                            <div className="d-flex d-lg-block d-xl-flex align-items-end">
                                <h3 id="total-expired" className="tx-normal tx-rubik mg-b-0 mg-r-5 lh-1">0</h3>
                            </div>
                            <span id="month-current" className="tx-11 tx-color-02 mg-b-0 mg-t-5">Number of Customer Owning</span>
                        </div>
                    </div>

                    <div className="col-sm-6 col-lg-3 mg-t-10 mg-sm-t-0">
                        <div className="card card-body">
                            <h6 className="tx-uppercase tx-11 tx-spacing-1 tx-danger tx-semibold mg-b-8">Total Cancel</h6>
                            <div className="d-flex d-lg-block d-xl-flex align-items-end">
                                <h3 id="total-cancelled" className="tx-normal tx-rubik mg-b-0 mg-r-5 lh-1">0</h3>
                            </div>
                            <span id="month-current" className="tx-11 tx-color-02 mg-b-0 mg-t-5">Cancel Subscription</span>
                        </div>
                    </div>

                    <div className="col-md-12 mg-t-10">
                        <div className="card card-crypto">
                            <div className="card-header d-flex align-items-center justify-content-between">
                                <nav className="nav nav-line">
                                    <Link to="#" className={`nav-link ${activeLink === 'All' ? 'active' : ''}`} onClick={() => handleNavClick('All')}>All</Link>
                                    <Link to="#" className={`nav-link ${activeLink === 'Paid' ? 'active' : ''}`} onClick={() => handleNavClick('Paid')}>Paid</Link>
                                    <Link to="#" className={`nav-link ${activeLink === 'Credit' ? 'active' : ''}`} onClick={() => handleNavClick('Credit')}>Credit</Link>
                                    <Link to="#" className={`nav-link ${activeLink === 'Expired' ? 'active' : ''}`} onClick={() => handleNavClick('Expired')}>Expired</Link>
                                    <Link to="#" className={`nav-link ${activeLink === 'Cancelled' ? 'active' : ''}`} onClick={() => handleNavClick('Cancelled')}>Cancelled</Link>
                                </nav>
                                <div className="d-flex tx-18">
                                    <div className="tx-12 tx-color-03 align-items-center d-none d-sm-flex mg-r-15">
                                        <a href="javascript:void(0)" className="link-01 tx-spacing-1 tx-rubik d-flex align-items-center" onClick={() => $(`#dateRangePicker`).datepicker('show')}>
                                            <DateRangePicker id="dateRangePicker" onDateRangeSelect={handleDateRangeSelect}/>
                                            {selectedDate ? <div><span id="start_date">{moment(currDateRange?.start).format('DD/MM/YYYY')}</span><span className="mg-x-10">to</span><span id="end_date">{moment(currDateRange?.end).format('DD/MM/YYYY')}</span></div> : <span>Select Date Range</span>}<ion-icon name="chevron-down-outline" aria-label="chevron down outline" role="img" className="md hydrated"></ion-icon>
                                        </a>
                                    </div>
                                    <a href="#" onClick={refreshTable.bind()} className="link-03 lh-0"><i className="icon ion-md-refresh"></i></a>
                                </div>
                            </div>
                            <div className="card-body">
                                <table id="td-subscription" className="table">
                                    <thead>
                                    <tr>
                                        <th></th>
                                        <th className="wd-20p">Name</th>
                                        <th className="wd-20p">Mobile Number</th>
                                        <th className="wd-20p">Type</th>
                                        <th className="wd-20p">Area</th>
                                        <th className="wd-15p">Expiry Date</th>
                                    </tr>
                                    </thead>
                                </table>
                            </div>
                        </div>
                    </div>

                    <div className="modal fade" id="modalSubscription" tabIndex="-1" role="dialog" aria-hidden="true">
                        <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                            <div className="modal-content">
                                <div className="modal-header pd-y-20 pd-x-20 pd-sm-x-30 border-bottom-0">
                                    <a href="#" role="button" className="close pos-absolute t-15 r-15" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </a>
                                    <div className="media-body"><a href="#" role="button" className="close pos-absolute t-15 r-15" data-dismiss="modal" onClick={resetForm.bind(null, "modalSubscription", "subscription_form")} aria-label="Close"><span aria-hidden="true">×</span></a><h4 className="tx-18 tx-sm-20 mg-b-2">Create Subscription</h4><p className="tx-13 tx-color-02 mg-b-0">Create subscription for existing customer.</p></div>
                                </div>
                                <div className="modal-body pd-0">
                                    <div id="wizard2">
                                        <h3>Subscription</h3>
                                        <section>
                                            <p className="mg-b-20">Fill all the information below.</p>
                                            <div className="row row-sm">
                                                <div className="col-md-12 form-group">
                                                    <label className="form-control-label">Customer: <span className="tx-danger">*</span></label>
                                                    <input id="customer" className="form-control" name="customer" placeholder="Select customer" type="text" required/>
                                                </div>

                                                <div className="col-md-12 form-group">
                                                    <label className="form-control-label">Number of Month: <span className="tx-danger">*</span></label>
                                                    <input id="total-month" className="form-control" name="total_month" placeholder="Enter number of month" type="number" required/>
                                                </div>

                                                <div className="col-md-12 form-group">
                                                    <label className="form-control-label">Number of Bin: <span className="tx-danger">*</span></label>
                                                    <input id="total-bin" className="form-control" name="total_bin" placeholder="Enter number of bin" type="number" required/>
                                                </div>
                                            </div>
                                        </section>
                                        <h3>Address Information</h3>
                                        <section>
                                            <p>Wonderful transition effects.</p>
                                            <div className="form-group">
                                                <label className="form-control-label">Email: <span className="tx-danger">*</span></label>
                                                <input id="email" className="form-control" name="email" placeholder="Enter email address" type="email" required/>
                                            </div>
                                        </section>
                                        <h3>Payment Details</h3>
                                        <section>
                                            <p>The next and previous buttons help you to navigate through your content.</p>
                                        </section>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default Subscription;
