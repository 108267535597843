import React, {useEffect, useState} from 'react';
import {
    GET_COLLECTION_DATA
} from '../../api';
import 'datatables.net';
import 'datatables.net-responsive';
import 'datatables.net-responsive-dt';
import 'datatables.net-editor';
import 'components-jqueryui';
import $ from "jquery";
import 'select2';
import {Link} from "react-router-dom";
import cogoToast from "cogo-toast";
import {agent} from "../../api/app";
import {useSelector} from "react-redux";
import moment from "moment";

const Agent = () => {

    document.title = "Agent";

    const auth = useSelector((state) => state.auth.user);

    const [stateReady, setStateReady] = useState(false);
    const [editForm, setEditForm] = useState(false);
    const [editId, setEditId] = useState(0);

    const token = localStorage.getItem('jwtToken');

    useEffect(() => {

        let table = $('#agent_table').DataTable({
            responsive: true,
            language: {
                searchPlaceholder: 'Search name',
                sSearch: '',
                lengthMenu: '_MENU_ items/page',
                processing: '<div class="spinner-border"></div><p class="mg-t-10 tx-12">Collecting Agent Data</p>'
            },
            searching: true,
            lengthMenu: [[10], [10]],
            ordering: false,
            info: true,
            bFilter: false,
            processing: true,
            pageLength: 10,
            serverSide: true,
            ajax: function (data, callback) {
                $.ajax(GET_COLLECTION_DATA, {
                    type: 'POST',
                    headers: {
                        "Authorization": token
                    },
                    data: {
                        query: $('.dataTables_filter input').val(),
                        collection: "agent",
                        fieldname: "first_name",
                        filter: {
                            collector: auth.user.sub
                        },
                        pageSize: data.start,
                        format: 'json',
                        pageIndex: (data.length + data.start) / data.length
                    },
                    success: function (res) {
                        let array = [];
                        res.data.map((data) => {
                            array.push({raw: data})
                        });
                        callback({
                            recordsTotal: res.total,
                            recordsFiltered: res.total,
                            data: array
                        });
                    },
                    error: function(err) {
                        cogoToast.error( err.responseJSON['error'].details !== undefined ? err.responseJSON['error'].details[0].message : err.responseJSON['error'], {position: "top-right", hideAfter: 5});
                    }
                });
            },
            "columns": [
                {
                    className: 'dt-control',
                    orderable: false,
                    data: null,
                    defaultContent: '',
                },
                {"data": "raw"},
                {"data": "raw"},
                {"data": "raw"},
                {"data": "raw"},
                {"data": "raw"}
            ],
            "columnDefs": [
                {
                    "render": function () {
                        return null;
                    },
                    "targets": 0
                },
                {
                    "render": function (data) {
                        return `${data.first_name} ${data.last_name}`;
                    },
                    "targets": 1
                },
                {
                    "render": function (data) {
                        return data.mobile_number;
                    },
                    "targets": 2
                },
                {
                    "render": function (data) {
                        return {
                            true : `<span class="badge badge-success tx-white">Active</span>`,
                            false : `<span class="badge badge-danger tx-white">Inactive</span>`
                        }[data.active];
                    },
                    "targets": 3
                },
                {
                    "render": function (data) {
                        return moment(data.date.str).format("Do MMM, YYYY")
                    },
                    "targets": 4
                },
                {
                    "render": function (data) {
                        return `<nav class="nav nav-icon-only"><a href="#" class="nav-link edit_agent"><i class="fa fa-pencil"></i> Edit</a> <a href="#" class="nav-link delete_agent"><i class="fa fa-trash-alt"></i> Delete</a></nav>`
                    },
                    "targets": 5
                }
            ]
        });

        table.on('click', '.edit_agent', function(e) {
            e.preventDefault();

            let extract_td = table.row($(this).closest('tr')).data();

            setEditForm(true);
            setEditId(extract_td.raw._id);

            $("#password").prop("required", false);
            $('#agent_form').parsley();

            $("#first_name").val(extract_td.raw.first_name);
            $("#last_name").val(extract_td.raw.last_name);
            $("#email").val(extract_td.raw.email);
            $("#id_number").val(extract_td.raw.id_number);
            $("#mobile_number").val(extract_td.raw.mobile_number);

            $("#modalAgent").modal("show");
        });

        table.on('click', '.delete_agent', function(e) {
            e.preventDefault();
            let extract_td = table.row($(this).closest('tr')).data();
            $(".data-message").text(`Are you sure, you want to delete, ${extract_td.raw.first_name}?`);
            $('#delete-data').unbind("click").click(function(){

                $("#modalConfirm").modal("hide");
                $(".modal-backdrop").remove();

                const options = {
                    position: "top-right",
                    hideAfter: 0
                };

                let {hide} = cogoToast.loading('Please wait... contacting to server.', options);

                let data = {
                    id: extract_td.raw._id,
                }

                agent("delete", data).then((result) => {
                    hide();
                    if(!result.error) {
                        reloadTable();
                        cogoToast.success("Agent successfully removed.", {position: "top-right"});
                    }
                });
            });

            $("#modalConfirm").modal({ backdrop: 'static', focus: false, show: true });
        });

        $('#agent_table tbody').on('click', 'td.dt-control', function () {
            let tr = $(this).closest('tr');
            let row = table.row(tr);

            if (row.child.isShown()) {
                // This row is already open - close it
                row.child.hide();
                tr.removeClass('shown');
            } else {
                // Open this row
                row.child(format(row.data())).show();
                tr.addClass('shown');
            }
        });

        const format = (d) => {
            // `d` is the original data object for the row
            return (
                '<table cellpadding="5" cellspacing="0" border="0" style="padding-left:50px;">' +
                '<tr>' +
                '<td>ID Number:</td>' +
                '<td>' +
                d.raw.id_number +
                '</td>' +
                '</tr>' +
                '<tr>' +
                '<td>Email:</td>' +
                '<td>' +
                d.raw.email +
                '</td>' +
                '</tr>' +
                '</table>'
            );
        }

        table.on('click', '.activate', function(e) {
            e.preventDefault();
            let extract_td = table.row($(this).closest('tr')).data();

            const options = {
                position: "top-right",
                hideAfter: 0
            };

            let {hide} = cogoToast.loading('Please wait... contacting to server.', options);

            let data = {
                id: extract_td.raw._id,
                activate: extract_td.raw.active !== true
            }

            agent("patch", data).then((result) => {
                hide();
                if(!result.error) {
                    reloadTable();
                    cogoToast.success(`Agent successfully ${extract_td.raw.active === true ? "deactivated" : "activated"}.`, {position: "top-right"});
                }
            });
        });

        return () => {
            table.destroy();
        }
    }, []);

    const handleAgentSubmit = (e) => {
        e.preventDefault();

        setStateReady(true);

        let first_name = $("#first_name").val();
        let last_name = $("#last_name").val();
        let email = $("#email").val();
        let id_number = $("#id_number").val();
        let mobile_number = $("#mobile_number").val();
        let password = $("#password").val() || null;

        if(editForm) {
            let update = {
                id: editId,
                first_name,
                last_name,
                email,
                id_number,
                mobile_number,
                password
            };

            agent("put", update).then((result) => {
                setStateReady(false);

                if(!result.error) {
                    cogoToast.success("Data successfully updated.", {position: "top-right", hideAfter: 5});
                    resetForm("modalAgent", "agent_form");
                    reloadTable();
                }
            });

            return false;
        }

        let data = {
            first_name,
            last_name,
            email,
            id_number,
            mobile_number,
            password,
            collector: auth.user.sub
        }

        agent("post", data).then((result) => {
            setStateReady(false);

            if(!result.error) {
                cogoToast.success("Data successfully created.", {position: "top-right", hideAfter: 5});
                resetForm("modalAgent", "agent_form");
                reloadTable();
            }
        });
    }

    const modalAgentForm = () => {
        setEditForm(false);
        $("#password").prop("required", true);
        $('#agent_form').parsley();
        $("#modalAgent").modal({ backdrop: 'static', focus: false, show: true });
    }

    const resetForm = (id, form) => {
        const parsley = $(`#${form}`).parsley();
        $(':input',`#${form}`)
            .not(':button, :submit, :reset, :hidden')
            .val('')
            .prop('checked', false)
            .prop('selected', false);
        $(`#${id}`).modal("hide");
        parsley.reset();
        parsley.destroy();
        $(".modal-backdrop").remove();
    };

    const reloadTable = () => {
        $('#agent_table').DataTable().ajax.reload(null, false);
    };

    return (
        <div className="content-body">
            <div className="container-fluid pd-x-0">
                <div className="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-25 mg-xl-b-30">
                    <div>
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb breadcrumb-style1 mg-b-10">
                                <li className="breadcrumb-item"><a href="pages/authorized/settings/Region#">Dashboard</a></li>
                                <li className="breadcrumb-item active" aria-current="page">Agents</li>
                            </ol>
                        </nav>
                        <h4 className="mg-b-0 tx-spacing--1">Agents</h4>
                    </div>
                    <div className="d-none d-md-block">
                        <button className="btn btn-sm pd-x-15 btn-success btn-uppercase mg-l-5 outline-none" onClick={modalAgentForm.bind()}><i className="wd-10 mg-r-5 fa fa-plus"></i> Add New</button>
                    </div>
                </div>

                <div className="row row-xs">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="card-header d-flex align-items-center justify-content-between">
                                <h6 className="mg-b-0">Agent List</h6>
                                <div className="d-flex tx-18">
                                    <Link to="#" onClick={reloadTable.bind()} className="link-03 lh-0"><i className="icon ion-md-refresh"></i></Link>
                                </div>
                            </div>
                            <div className="card-body table-responsive">
                                <table id="agent_table" className="table table-hover" style={{width: "100%"}}>
                                    <thead>
                                    <tr>
                                        <th></th>
                                        <th className="wd-20p">Name</th>
                                        <th className="wd-20p">Mobile Number</th>
                                        <th className="wd-20p">Job Status</th>
                                        <th className="wd-20p">Date</th>
                                        <th className="wd-20p">Actions</th>
                                    </tr>
                                    </thead>
                                </table>
                            </div>
                        </div>
                    </div>

                    <div className="modal fade" id="modalAgent" tabIndex="-1" role="dialog" aria-hidden="true">
                        <div className="modal-dialog modal-dialog-centered wd-sm-650" role="document">
                            <div className="modal-content">
                                <div className="modal-header pd-y-20 pd-x-20 pd-sm-x-30">
                                    <a href="#" role="button" className="close pos-absolute t-15 r-15" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </a>
                                    <div className="media-body"><a href="" role="button" className="close pos-absolute t-15 r-15" data-dismiss="modal" onClick={resetForm.bind(null, "modalAgent", "agent_form")} aria-label="Close"><span aria-hidden="true">×</span></a><h4 className="tx-18 tx-sm-20 mg-b-2">{editForm ? "Edit Agent" : "New Agent"}</h4><p className="tx-13 tx-color-02 mg-b-0">{editForm ? "Edit" : "Create"} agent information.</p></div>
                                </div>
                                <div className="modal-body pd-sm-t-30 pd-sm-b-40 pd-sm-x-30">
                                    <form id="agent_form" className="parsley-style-1" onSubmit={handleAgentSubmit.bind()}>

                                        <div id="fnameWrapper" className="form-group parsley-input">
                                            <label>First Name<span className="tx-danger">*</span></label>
                                            <input id="first_name" type="text" className="form-control"
                                                   placeholder="Enter first name"
                                                   autoComplete="none"
                                                   data-parsley-class-handler="#fnameWrapper" required/>
                                        </div>

                                        <div id="lnameWrapper" className="form-group parsley-input">
                                            <label>Last Name<span className="tx-danger">*</span></label>
                                            <input id="last_name" type="text" className="form-control"
                                                   placeholder="Enter last name"
                                                   autoComplete="none"
                                                   data-parsley-class-handler="#lnameWrapper" required/>
                                        </div>

                                        <div id="emailWrapper" className="form-group parsley-input">
                                            <label>Email<span className="tx-danger">*</span></label>
                                            <input id="email" type="email" className="form-control"
                                                   placeholder="Enter email address"
                                                   autoComplete="none"
                                                   data-parsley-class-handler="#emailWrapper" required/>
                                        </div>

                                        <div id="mobileWrapper" className="form-group parsley-input">
                                            <label>Mobile Number<span className="tx-danger">*</span></label>
                                            <input id="mobile_number" type="number" className="form-control"
                                                   placeholder="Enter mobile number"
                                                   autoComplete="none"
                                                   data-parsley-class-handler="#mobileWrapper" required/>
                                        </div>

                                        <div id="idNumberWrapper" className="form-group parsley-input">
                                            <label>ID Number<span className="tx-danger">*</span></label>
                                            <input id="id_number" type="text" className="form-control"
                                                   placeholder="Enter ID Number"
                                                   autoComplete="none"
                                                   data-parsley-class-handler="#idNumberWrapper" required/>
                                        </div>

                                        <div id="passwordWrapper" className="form-group parsley-input">
                                            <label>{editForm ? "Password" : "Create Password"}<span className="tx-danger">*</span></label>
                                            <input id="password" type="text" className="form-control"
                                                   placeholder="Enter login password"
                                                   autoComplete="none"
                                                   data-parsley-class-handler="#passwordWrapper" required/>
                                        </div>

                                        {stateReady ? <button disabled className="btn btn-success btn-block"><span className="spinner-border spinner-border-sm mg-r-10" role="status" aria-hidden="true"/>Please Wait</button> : editForm ? <button className="btn btn-success btn-block">Update Agent</button> : <button className="btn btn-success btn-block">Create Agent</button>}
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="modal fade" id="modalConfirm" tabIndex="-1" role="dialog" aria-labelledby="confirmation" aria-hidden="true">
                        <div className="modal-dialog modal-dialog-centered modal-sm" role="document">
                            <div className="modal-content tx-14">
                                <div className="modal-header">
                                    <h6 className="modal-title">Delete Confirm?</h6>
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div className="modal-body">
                                    <p className="mg-b-0 data-message">Empty</p>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" id="delete-data" className="btn btn-success btn-block">Yes, Proceed</button>
                                    <button type="button" className="btn btn-danger btn-block mt-0" data-dismiss="modal">Cancel</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default Agent;
