import React, {useEffect} from 'react';
import {Link, useLocation} from 'react-router-dom';
import $ from "jquery";
import feather from "feather-icons";
import {logoutUser} from '../../api/auth';
import PerfectScrollbar from 'perfect-scrollbar';
import {useDispatch, useSelector} from "react-redux";

const Header = () => {
    const dispatch = useDispatch();
    const auth = useSelector((state) => state.auth.user);

    const location = useLocation();
    const { pathname } = location;

    const onLogoutClick = (event) => {
        event.preventDefault();
        dispatch(logoutUser());
    };

    useEffect(() => {
        $(function () {
            'use strict';
            feather.replace();

            const asideBody = new PerfectScrollbar('.aside-body', {
                suppressScrollX: true
            });

            if($('.aside-backdrop').length === 0) {
                $('body').append('<div class="aside-backdrop"></div>');
            }

            var mql = window.matchMedia('(min-width:992px) and (max-width: 1199px)');

            function doMinimize(e) {
                if (e.matches) {
                    $('.aside').addClass('minimize');
                } else {
                    $('.aside').removeClass('minimize');
                }

                asideBody.update()
            }

            mql.addListener(doMinimize);
            doMinimize(mql);

            $('.aside-menu-link').on('click', function(e){
                e.preventDefault();

                if(window.matchMedia('(min-width: 992px)').matches) {
                    $(this).closest('.aside').toggleClass('minimize');
                } else {

                    $('body').toggleClass('show-aside');
                }

                asideBody.update()
            });

            $('.nav-aside .with-sub').on('click', '.nav-link', function(e){
                e.preventDefault();

                $(this).parent().siblings().removeClass('show');
                $(this).parent().toggleClass('show');

                asideBody.update()
            });

            $('body').on('mouseenter', '.minimize .aside-body', function(e){
                console.log('e');
                $(this).parent().addClass('maximize');
            });

            $('body').on('mouseleave', '.minimize .aside-body', function(e){
                $(this).parent().removeClass('maximize');

                asideBody.update()
            });

            $('body').on('click', '.aside-backdrop', function(e){
                $('body').removeClass('show-aside');
            })
        });
    }, []);

    return (
        <aside className="aside aside-fixed">
            <div className="aside-header">
                <a href="#" className="aside-logo"><img src="assets/img/name.svg" className="ht-20 img-fluid" alt="logo"/></a>
                <a href="#" className="aside-menu-link">
                    <i data-feather="menu"></i>
                    <i data-feather="x"></i>
                </a>
            </div>
            <div className="aside-body">
                <div className="aside-loggedin">
                    <div className="d-flex align-items-center justify-content-start">
                        <a href="" className="avatar"><img src={auth.user.logo_uri} className="rounded-circle" alt=""/></a>
                        <div className="aside-alert-link">
                            {/*<a href="" className="new" data-toggle="tooltip" title="You have 2 unread messages"><i data-feather="message-square"></i></a>*/}
                            {/*<a href="" className="new" data-toggle="tooltip" title="You have 4 new notifications"><i data-feather="bell"></i></a>*/}
                            <Link to="#" data-toggle="tooltip" title="Sign out" onClick={onLogoutClick.bind()}><i data-feather="log-out"></i></Link>
                        </div>
                    </div>
                    <div className="aside-loggedin-user">
                        <a href="#loggedinMenu" className="d-flex align-items-center justify-content-between mg-b-2" data-toggle="collapse">
                            <h6 className="tx-semibold mg-b-0">{auth.user.name}</h6>
                            <i data-feather="chevron-down"></i>
                        </a>
                        <p className="tx-color-03 tx-12 mg-b-0">Administrator</p>
                    </div>
                    <div className="collapse" id="loggedinMenu">
                        <ul className="nav nav-aside mg-b-0">
                            <li className="nav-item"><Link to="#" onClick={onLogoutClick.bind()} className="nav-link"><i data-feather="log-out"></i>
                                <span>Sign Out</span></Link>
                            </li>
                        </ul>
                    </div>
                </div>

                <ul className="nav nav-aside">
                    <li className="nav-label">General</li>
                    <li className={`nav-item ${pathname === '/overview' ? 'active' : ''}`}><Link to="/overview" className="nav-link"><i data-feather="activity"></i> <span>Overview</span></Link></li>
                    <li className={`nav-item ${pathname === '/customer' ? 'active' : ''}`}><Link to="/customer" className="nav-link"><i data-feather="users"></i> <span>Customers</span></Link></li>
                    <li className={`nav-item with-sub ${pathname.startsWith('/waste') ? 'active show' : ''}`}>
                        <a href="#" className="nav-link">
                            <i data-feather="trash-2"></i> <span>Wastes</span>
                        </a>
                        <ul>
                            <li className={pathname === '/waste/subscription' ? 'active' : ''}>
                                <Link to="/waste/subscription">Subscription</Link>
                            </li>
                        </ul>
                    </li><li className={`nav-item ${pathname === '/collection' ? 'active' : ''}`}><Link to="/collection" className="nav-link"><i data-feather="anchor"></i> <span>Collections</span></Link></li>
                    <li className={`nav-item ${pathname === '/finance' ? 'active' : ''}`}><Link to="/finance" className="nav-link"><i data-feather="trending-up"></i> <span>Finances</span></Link></li>
                    <li className={`nav-item ${pathname === '/agent' ? 'active' : ''}`}><Link to="/agent" className="nav-link"><i data-feather="truck"></i> <span>Agents</span></Link></li>
                    <li className={`nav-item ${pathname === '/rate' ? 'active' : ''}`}><Link to="/rate" className="nav-link"><i data-feather="star"></i> <span>Rating</span></Link></li>
                    <li className={`nav-item ${pathname === '/schedule' ? 'active' : ''}`}><Link to="/schedule" className="nav-link"><i data-feather="calendar"></i> <span>Schedule</span></Link></li>
                    <li className="nav-label mg-t-25">Settings</li>
                    <li className={`nav-item ${pathname === '/zone' ? 'active' : ''}`}><Link to="/zone" className="nav-link"><i data-feather="globe"></i> <span>Zones</span></Link></li>
                    <li className={`nav-item ${pathname === '/product' ? 'active' : ''}`}><Link to="/product" className="nav-link"><i data-feather="box"></i> <span>Products</span></Link></li>
                    <li className={`nav-item ${pathname === '/account' ? 'active' : ''}`}><Link to="/account" className="nav-link"><i data-feather="user"></i> <span>Account</span></Link></li>
                </ul>
            </div>
        </aside>
    );
};

export default Header;
