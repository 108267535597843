import React, {useEffect, useState, useMemo, useRef} from 'react';
import 'datatables.net';
import 'datatables.net-responsive';
import 'components-jqueryui';
import $ from "jquery";
import 'select2';
import {useDispatch, useSelector} from "react-redux";
import {logoutUser, updateAuthToken} from "../../../api/auth";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import {account} from "../../../api/app";
import cogoToast from "cogo-toast";

const Account = () => {

    document.title = "Account";

    const dispatch = useDispatch();
    const quillRef = useRef();
    const auth = useSelector((state) => state.auth.user);

    const [stateCollectorReady, setStateCollectorReady] = useState(false);
    const [statePasswordReady, setStatePasswordReady] = useState(false);
    const [statePolicyReady, setStatePolicyReady] = useState(false);
    const [value, setValue] = useState("");

    useEffect(() => {
        $(".ql-editor")[0].innerHTML = b64DecodeUnicode(auth.user.meta.policy);
        $("#name").val(auth.user.name);
        $("#email").val(auth.user.email);
        $("#mobile_number").val(auth.user.mobile_number);
        $("#address").val(auth.user.address);
        $("#tax").val(auth.user.tax_id);
        $("#collector_form").parsley();
    }, []);

    const resetForm = (form) => {
        const parsley = $(`#${form}`).parsley();
        $(':input',`#${form}`)
            .not(':button, :submit, :reset, :hidden')
            .val('')
            .prop('checked', false)
            .prop('selected', false);
        parsley.reset();
        parsley.destroy();
        $(".modal-backdrop").remove();
    };

    const onLogoutClick = (event) => {
        event.preventDefault();
        dispatch(logoutUser());
    };

    const modules = useMemo(
        () => ({
            toolbar: {
                container: [
                    [{ 'header': '1'}, {'header': '2'}, { 'font': [] }],
                    [{size: []}],
                    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                    [{'list': 'ordered'}, {'list': 'bullet'},
                        {'indent': '-1'}, {'indent': '+1'}],
                    ['clean']
                ],
                history: {
                    delay: 500,
                    maxStack: 100,
                    userOnly: true,
                },
            },
        }),
        []
    );

    const handlePersonalSubmit = async(e) => {
        e.preventDefault();

        let payload = {
            action: "personal",
            email: $("#email").val(),
            mobile_number: $("#mobile_number").val(),
            address: $("#address").val(),
            password: $("#current_password").val()
        }

        setStateCollectorReady(true);

        account("put", payload).then((result) => {

            setStateCollectorReady(false);

            if(!result.error) {
                dispatch(updateAuthToken(result.payload));
                $("#current_password").val(null);
                cogoToast.success("Profile successfully updated.", {position: "top-right", hideAfter: 5});
            }
        });
    }

    const handlePolicySubmit = (e) => {
        e.preventDefault();

        let payload = {
            action: "policy",
            policy: b64EncodeUnicode(value)
        }

        setStatePolicyReady(true);

        account("put", payload).then((result) => {

            setStatePolicyReady(false);

            if(!result.error) {
                dispatch(updateAuthToken(result.payload));
                $(".ql-editor")[0].innerHTML = b64DecodeUnicode(b64EncodeUnicode(value));
                cogoToast.success("Policy successfully updated.", {position: "top-right", hideAfter: 5});
            }
        });
    }

    const b64EncodeUnicode = (str) => {
        return btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, function(match, p1) {
            return String.fromCharCode('0x' + p1);
        }));
    };

    const b64DecodeUnicode = (str) => {
        return decodeURIComponent(atob(str).split('').map(function(c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));
    }

    const handlePasswordSubmit = (e) => {
        e.preventDefault();

        let payload = {
            action: "password",
            password: $("#password").val(),
            new_password: $("#new_password").val(),
            confirm_password: $("#confirm_password").val()
        }

        setStatePasswordReady(true);

        account("put", payload).then((result) => {

            setStatePasswordReady(false);

            if(!result.error) {
                updateAuthToken(result.token);
                cogoToast.success("Password successfully updated.", {position: "top-right", hideAfter: 5});
                resetForm("password_form");
            }
        });
    }

    return (
        <div className="content-body">
            <div className="container-fluid pd-x-0">
                <div className="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-25 mg-xl-b-30">
                    <div>
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb breadcrumb-style1 mg-b-10">
                                <li className="breadcrumb-item"><a href="#">Settings</a></li>
                                <li className="breadcrumb-item active" aria-current="page">Account</li>
                            </ol>
                        </nav>
                        <h4 className="mg-b-0 tx-spacing--1">Account</h4>
                    </div>
                    <div className="d-none d-md-block">
                        <button className="btn btn-sm pd-x-15 btn-danger btn-uppercase mg-l-5 outline-none" onClick={onLogoutClick.bind()}><i className="fa fa-lock"></i> Log Out</button>
                    </div>
                </div>

                <div className="row row-xs">
                    <div className="col-md-12 mg-t-10">
                        <div className="nav-wrapper mg-b-20 tx-13">
                            <div>
                                <ul id="nav_basic" className="nav nav-line nav-fill tx-medium">
                                    <li className="nav-item"><a href="#basic " className="nav-link active" data-toggle="tab"><i className="ion-md-information-circle"/> Personal Information</a></li>
                                    <li className="nav-item"><a href="#editor" className="nav-link" data-toggle="tab" onClick={() => $("#policy_form").parsley()}><i className="ion-md-locate"/> Policy Editor</a></li>
                                    <li className="nav-item"><a href="#change-password" className="nav-link" data-toggle="tab" onClick={() => $("#password_form").parsley()}><i className="ion-md-trash"/> Change Password</a></li>
                                </ul>
                            </div>
                        </div>

                        <div className="tab-content">
                            <div id="basic" className="tab-pane fade active show">
                                <div className="row row-sm">
                                    <div className="col-12">
                                        <form id="collector_form" className="parsley-style-1"
                                              onSubmit={handlePersonalSubmit.bind()}>
                                            <div id="nameWrapper" className="form-group parsley-input">
                                                <label>Company Name<span className="tx-danger">*</span></label>
                                                <input id="name" type="text" className="form-control"
                                                       placeholder="Enter company name"
                                                       autoComplete="none"
                                                       data-parsley-class-handler="#nameWrapper"
                                                       disabled/>
                                            </div>

                                            <div id="emailWrapper" className="form-group parsley-input">
                                                <label>Email<span className="tx-danger">*</span></label>
                                                <input id="email" type="email" className="form-control"
                                                       placeholder="Enter email address"
                                                       autoComplete="none"
                                                       data-parsley-class-handler="#emailWrapper"
                                                       disabled/>
                                            </div>

                                            <div id="taxWrapper" className="form-group parsley-input">
                                                <label>Tax ID</label>
                                                <input id="tax" type="text" className="form-control"
                                                       placeholder="Enter tax ID or TPIN #"
                                                       autoComplete="none"
                                                       disabled
                                                       data-parsley-class-handler="#taxWrapper"/>
                                            </div>

                                            <div id="mobileWrapper" className="form-group parsley-input">
                                                <label>Mobile Number<span className="tx-danger">*</span></label>
                                                <input id="mobile_number" type="number" className="form-control"
                                                       placeholder="Enter mobile number"
                                                       autoComplete="none"
                                                       data-parsley-class-handler="#mobileWrapper" required/>
                                            </div>

                                            <div id="addressWrapper" className="form-group parsley-input">
                                                <label>Physical Address<span className="tx-danger">*</span></label>
                                                <input id="address" type="text" className="form-control"
                                                       placeholder="Enter physical address"
                                                       autoComplete="none"
                                                       data-parsley-class-handler="#addressWrapper" required/>
                                            </div>

                                            <div id="currentPasswordWrapper" className="form-group parsley-input">
                                                <label>Current Password<span className="tx-danger">*</span></label>
                                                <input id="current_password" type="text" className="form-control"
                                                       placeholder="Enter current password"
                                                       autoComplete="none"
                                                       data-parsley-class-handler="#currentPasswordWrapper" required/>
                                            </div>

                                            {stateCollectorReady ?
                                                <button disabled className="btn btn-success btn-block"><span className="spinner-border spinner-border-sm mg-r-10" role="status" aria-hidden="true"/>Please Wait</button> :
                                                <button className="btn btn-success btn-block">Update Collector</button>}
                                        </form>
                                    </div>
                                </div>
                            </div>

                            <div id="editor" className="tab-pane fade">
                                <label>Instruction<span className="tx-danger">*</span></label>
                                <ReactQuill
                                    id={"quill-id"}
                                    theme="snow"
                                    value={value}
                                    onChange={setValue}
                                    modules={modules}
                                    ref={quillRef}
                                    placeholder={'Write something...'}
                                />
                                {statePolicyReady ? <button disabled className="btn btn-success btn-block mt-3"><span
                                        className="spinner-border spinner-border-sm mg-r-10" role="status"
                                        aria-hidden="true"/>Please Wait</button> :
                                    <button className="btn btn-success btn-block mt-3" onClick={handlePolicySubmit.bind()}>Update Policy</button>}
                            </div>

                            <div id="change-password" className="tab-pane fade">
                                <form id="password_form" className="parsley-style-1"
                                      onSubmit={handlePasswordSubmit.bind()}>
                                    <div id="passwordWrapper" className="form-group parsley-input">
                                        <label>Current Password<span className="tx-danger">*</span></label>
                                        <input id="password" type="text" className="form-control"
                                               placeholder="Enter current password"
                                               autoComplete="none"
                                               data-parsley-class-handler="#passwordWrapper" required/>
                                    </div>

                                    <div id="npasswordWrapper" className="form-group parsley-input">
                                        <label>New Password<span className="tx-danger">*</span></label>
                                        <input id="new_password" type="text" className="form-control"
                                               placeholder="Enter new password"
                                               autoComplete="none"
                                               data-parsley-class-handler="#npasswordWrapper" required/>
                                    </div>

                                    <div id="cpasswordWrapper" className="form-group parsley-input">
                                        <label>Confirm Password<span className="tx-danger">*</span></label>
                                        <input id="confirm_password" type="text" className="form-control"
                                               placeholder="Enter confirm password"
                                               autoComplete="none"
                                               data-parsley-class-handler="#cpasswordWrapper" required/>
                                    </div>

                                    {statePasswordReady ? <button disabled className="btn btn-success btn-block"><span
                                            className="spinner-border spinner-border-sm mg-r-10" role="status"
                                            aria-hidden="true"/>Please Wait</button> :
                                        <button className="btn btn-success btn-block">Update Password</button>}
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default Account;
